var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "error-page flex-column" }, [
    _c("div", { staticClass: "error-page-wrapper flex-align" }, [
      _c("div", { staticClass: "describe" }, [
        _c("p", { staticClass: "text" }, [
          _vm._v("抱歉！您所访问的页面不存在，请重新加载！"),
        ]),
        _c("div", { staticClass: "btn-group" }, [
          _c(
            "button",
            {
              staticClass: "csp",
              on: {
                click: function ($event) {
                  return _vm.$router.go(0)
                },
              },
            },
            [
              _c("i", { staticClass: "el-icon-refresh-right" }),
              _vm._v("刷新网页"),
            ]
          ),
          _c(
            "button",
            {
              staticClass: "csp",
              on: {
                click: function ($event) {
                  return _vm.$router.push("/home")
                },
              },
            },
            [
              _c("i", { staticClass: "el-icon-arrow-left" }),
              _vm._v(" 返回首页"),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }